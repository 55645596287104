import Misc from "./components/Misc";
import Project1 from "./components/Project1";
import Project4 from "./components/Project4";
import Project2 from "./components/Project2";
import Project3 from "./components/Project3";
import Home from "./components/Home";
import CAD from "./components/CAD";
import { Canvas, Camera } from "@react-three/fiber";
import { OrbitControls, useGLTF } from "@react-three/drei";
import React, { useRef, Suspense } from "react";

import {
  BrowserRouter,
  BrowserRouter as Router,
  Route,
  Link,
  Redirect,
  Routes,
} from "react-router-dom";
import ReactDOM from "react-dom/client";
import createRoot from "react-dom/client";
import "./App.css";
import ScrollToTop from "./components/ScrollToTop";

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/Misc" element={<Misc />} />
        <Route path="/Project1" element={<Project1 />} />
        <Route path="/Project4" element={<Project4 />} />
        <Route path="/Project2" element={<Project2 />} />
        <Route path="/Project3" element={<Project3 />} />
        <Route path="/CAD" element={<CAD />} />
        <Route path="/Home" element={<Home />} />
        {/* <Route path="/Home" element={<work />} />
        <Route path="/Home" element={<about />} />
        <Route path="/Home" element={<contact />} /> */}
        <Route path="/Home/:id" element={<work />} />
        <Route path="/Home/:id" element={<about />} />
        <Route path="/Home/:id" element={<contact />} />

        <Route path="/Project1/:id" element={<workdescriptionpr />} />
        <Route path="/Project4/:id" element={<workdescription />} />
        <Route path="/Project2/:id" element={<workdescription />} />
        <Route path="/Project3/:id" element={<workdescription />} />
        <Route path="/CAD/:id" element={<CAD />} />
        <Route path="/Misc/:id" element={<centered />} />
        {/* <Redirect to="/" /> */}
      </Routes>
    </BrowserRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);
