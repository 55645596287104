import React from "react";
import { HashLink as Link } from "react-router-hash-link";
import Home from "./Home";

import { Carousel } from "react-responsive-carousel";
import Gallery from "./01gallery";
// import video5 from ".//img/elephant.mov";

class Project1 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
    };
  }

  toggleExpand = () => {
    this.setState((prevState) => ({
      expanded: !prevState.expanded,
    }));
  };
  render() {
    const { expanded } = this.state;
    return (
      <div className="Project1">
        <>
          <div className="ButtonMenu">
            <Link to="../Home#about">
              <button className="Menu">&#60;About&#62;</button>
            </Link>
            <Link to="../Home#work">
              <button className="Menu">&#60;Work&#62;</button>
            </Link>
            <button>
              <Link to="../Home#contact" className="Menu">
                &#60;Contact&#62;
              </Link>
            </button>
          </div>
          <div id="workdescriptionpr"></div>

          <figure className="image1">
            <img src="/img/p1object1.png" className="img_gallery2" />
            <p className="appear">Arthropod image</p>
          </figure>
          <div className="workbody">
            <div className="leftside1">
              <div id="workdescription">
                {" "}
                <h4>
                  ARTHROPOD - <br />
                  <span className="h2description">
                    {" "}
                    A modular solution to shaping spaces{" "}
                  </span>
                </h4>
                <section id="projecttext">
                  <div className={`projecttext ${expanded ? "expanded" : ""}`}>
                    {/* <h2>[INFO]</h2> */}
                    <p>
                      The Arthropod is designed for living and working spaces
                      that lack privacy, arrangement, storage space, or just a
                      place where you can comfortably work or relax without
                      distractions. The front of the units has sound-absorbing
                      felt inserts, whereas the back can be used for storage. In
                      addition, the Modular units can be placed either way on a
                      desk to transform the space to fit the user's needs.
                    </p>
                  </div>
                  <button className="expand-btn" onClick={this.toggleExpand}>
                    {expanded ? "[INFO] -" : "[INFO] + "}
                  </button>
                </section>
                <section id="projecttext">
                  <div className="projecttext">
                    {/* <figure className="img_gallery3">
                      <img className="image2" src="/img/p1object2.png" />

                      <img className="image3" src="/img/p1object3.png" />
                    </figure> */}
                  </div>
                </section>
              </div>
            </div>
          </div>
        </>

        <div className="galleryplacement1">
          <Gallery />
        </div>
      </div>
    );
  }
}

export default Project1;
