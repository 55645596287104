import React from "react";
import { Carousel } from "react-responsive-carousel";
import "./carousel.min.css";
class Gallery extends React.Component {
  render() {
    return (
      <div>
        <h2>[PROCESS]</h2>
        <Carousel
          thumbWidth="auto"
          interval="1000"
          transitionTime="1000"
          infiniteLoop
        >
          <div>
            <img src="./img/g1/g-01.png" alt="" />
          </div>
          <div>
            <img src="./img/g1/g-02.png" alt="" />
          </div>
          <div>
            <img src="./img/g1/g-03.png" alt="" />
          </div>
          <div>
            <img src="./img/g1/g-04.png" alt="" />
          </div>
          <div>
            <img src="./img/g1/g-05.png" alt="" />
          </div>
          <div>
            <img src="./img/g1/g-06.png" alt="" />
          </div>
          <div>
            <img src="./img/g1/g-07.png" alt="" />
          </div>
          <div>
            <img src="./img/g1/g-08.png" alt="" />
          </div>
          <div>
            <img src="./img/g1/g-09.png" alt="" />
          </div>
          <div>
            <img src="./img/g1/g-10.png" alt="" />
          </div>

          <div>
            <img src="./img/g1/g-11.png" alt="" />
            {/* <p className="legend">My Photo 5</p> */}
          </div>
          <div>
            <img src="./img/g1/g-12.png" alt="" />
            {/* <p className="legend">My Photo 5</p> */}
          </div>
        </Carousel>
      </div>
    );
  }
}
export default Gallery;
