import React from "react";
import { HashLink as Link } from "react-router-hash-link";
import Home from "./Home";

import { GlassMagnifier } from "react-image-magnifiers";

import ThreeSixty from "./react-360-viewer";

class Project2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
    };
  }

  toggleExpand = () => {
    this.setState((prevState) => ({
      expanded: !prevState.expanded,
    }));
  };
  render() {
    const { expanded } = this.state;
    return (
      <div className="Project2">
        <div className="ButtonMenu">
          <Link to="../Home#about">
            <button className="Menu">&#60;About&#62;</button>
          </Link>
          <Link to="../Home#work">
            <button className="Menu">&#60;Work&#62;</button>
          </Link>
          <button>
            <Link to="../Home#contact" className="Menu">
              &#60;Contact&#62;
            </Link>
          </button>
        </div>

        <div id="workdescription"></div>

        <div className="workbody">
          <GlassMagnifier
            className="glassmagnifier"
            imageSrc="/img/cells.png"
            magnifierBorderSize={2}
            imageAlt="Magnified Image"
            magnifierBorderColor="rgba(255, 255, 255, 1)"
            magnifierSize={200}
            largeImageSrc="/img/cellslarge.png"
          />

          <div id="workdescription">
            {" "}
            <h4>
              SYNTH <br />
            </h4>{" "}
            <section id="projecttext">
              <div className="projecttext3">
                <h2>[INFO]</h2>
                <p>
                  A set of lamps that feature an intricate plant cell pattern
                  that is revealed through light. Synth aims to create a sense
                  of balance between the natural world and our human-made
                  environments. <br /> <br />
                  [PET 3D print, acrylic paint]
                </p>
              </div>
            </section>
          </div>
        </div>
        <div className="centered">
          <p>[360° Viewer]</p>
        </div>

        <div>
          <figure>
            <img src="/img/lamp4.png" alt="Set of lamps" className="bigimage" />
          </figure>
        </div>

        <div className="tsbox">
          <ThreeSixty
            className="v360-viewport"
            // autoplay="24"
            // loop="5"
            amount={24}
            imagePath="./img/sequenceone"
            fileName="{index}.png"
            style={{
              left: 0,
              top: 0,
              right: 0,
            }}
          />
          <ThreeSixty
            className="v360-viewport"
            amount={24}
            imagePath="./img/sequencetwo"
            fileName="{index}.png"
            style={{
              left: 0,
              top: 0,
              right: 0,
            }}
          />
          <ThreeSixty
            className="v360-viewport"
            amount={24}
            imagePath="./img/sequencethree"
            fileName="{index}.png"
            style={{
              left: 0,
              top: 0,
              right: 0,
            }}
          />
        </div>
      </div>
    );
  }
}

export default Project2;
