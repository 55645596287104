import React from "react";
import { HashLink as Link } from "react-router-hash-link";
import Home from "./Home";

import Gallerytwo from "./02gallery";

// import video5 from ".//img/elephant.mov";

class Project3 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
    };
  }

  toggleExpand = () => {
    this.setState((prevState) => ({
      expanded: !prevState.expanded,
    }));
  };
  render() {
    const { expanded } = this.state;
    return (
      <div className="Project3">
        <div id="workdescription"></div>
        <>
          <div className="ButtonMenu">
            <Link to="../Home#about">
              <button className="Menu">&#60;About&#62;</button>
            </Link>
            <Link to="../Home#work">
              <button className="Menu">&#60;Work&#62;</button>
            </Link>
            <button>
              <Link to="../Home#contact" className="Menu">
                &#60;Contact&#62;
              </Link>
            </button>
          </div>
        </>

        <div className="workbody">
          <figure>
            <img
              src="/img/scoutimg.png"
              alt="Scout image"
              className="img_gallery6"
            />
          </figure>
          <div id="workdescription">
            {" "}
            <h4>
              SCOUT - <br />
              <span className="h2description">
                {" "}
                High quality pet products for people on the go{" "}
              </span>
            </h4>
            <section id="projecttext">
              <div className={`projecttext ${expanded ? "expanded" : ""}`}>
                {/* <h2>[INFO]</h2> */}
                <p>
                  At SCOUT, we prioritize sustainability and functionality in
                  our products. We use premium materials, such as upcycled
                  military fabric, to ensure our products are both durable and
                  comfortable. Our range includes pet carriers and accessories
                  like leash pouches, all designed to enhance the outdoor
                  adventures and everyday experiences of pets and their owners.
                </p>
              </div>
              <button className="expand-btn" onClick={this.toggleExpand}>
                {expanded ? "[INFO] -" : "[INFO] + "}
              </button>
            </section>
          </div>
        </div>
        <div className="galleryplacement">
          <Gallerytwo />
        </div>
      </div>
    );
  }
}

export default Project3;
