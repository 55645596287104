import React from "react";
import { Carousel } from "react-responsive-carousel";
import "./carousel.min.css";
class Galleryfive extends React.Component {
  render() {
    return (
      <div>
        <h2>[RAZER Mouse]</h2>
        <Carousel
          thumbWidth="20"
          interval="1000"
          transitionTime="1000"
          infiniteLoop
        >
          <div>
            <img src="./img/g5/g-01.png" alt="" />
          </div>
          <div>
            <img src="./img/g5/g-02.png" alt="" />
          </div>
          <div>
            <img src="./img/g5/g-03.png" alt="" />
          </div>
          <div>
            <img src="./img/g5/g-04.png" alt="" />
          </div>
          <div>
            <img src="./img/g5/g-05.png" alt="" />
          </div>
          <div>
            <img src="./img/g5/g-06.png" alt="" />
          </div>
          <div>
            <img src="./img/g5/g-07.png" alt="" />
          </div>
          <div>
            <img src="./img/g5/g-08.png" alt="" />
          </div>
          <div>
            <img src="./img/g5/g-09.png" alt="" />
          </div>
        </Carousel>
      </div>
    );
  }
}
export default Galleryfive;
