import React from "react";
import { HashLink as Link } from "react-router-hash-link";
import Home from "./Home";

//

class Project4 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
    };
  }

  toggleExpand = () => {
    this.setState((prevState) => ({
      expanded: !prevState.expanded,
    }));
  };
  render() {
    const { expanded } = this.state;
    return (
      <div className="Project4">
        <>
          <div className="ButtonMenu">
            <Link to="../Home#about">
              <button className="Menu">&#60;About&#62;</button>
            </Link>
            <Link to="../Home#work">
              <button className="Menu">&#60;Work&#62;</button>
            </Link>
            <button>
              <Link to="../Home#contact" className="Menu">
                &#60;Contact&#62;
              </Link>
            </button>
          </div>
        </>
        <div id="workdescription"></div>

        <div className="workbody">
          <figure>
            <img src="/img/winopackaging2.png" className="img_gallery5" />
          </figure>
          <div id="workdescription">
            {" "}
            <h4>
              Terra - <br />
              <span className="h2description">
                {" "}
                Orange wine, made from noble Pinot Grigio grown in our vineyards
                of Napa Valley.{" "}
              </span>
            </h4>
            <section id="projecttext">
              <div className={`projecttext ${expanded ? "expanded" : ""}`}>
                {/* <h2>[INFO]</h2> */}
                <p>
                  Terra is a love letter to the riches of our planet and the
                  complexity of aromas and flavors it offers us to enjoy. 24-day
                  skin contact gives this wine a rich amber hue with delicate
                  notes of orange peel, beeswax, buckwheat honey, apple,
                  apricot, and toasted oak. This wine pairs well with hearty
                  mushrooms, roasted vegetables, savory cheese, and variety of
                  meat dishes. <br /> <br />
                  Bottled by St. Helena, CA <br />
                  Serve at 50°F(10°C) <br />
                  12.5% ABV
                </p>
              </div>
            </section>
            <button className="expand-btn" onClick={this.toggleExpand}>
              {expanded ? "[INFO] -" : "[INFO] + "}
            </button>
          </div>
        </div>

        {/* <div className="galleryplacement">
          <Gallerytwo />
        </div> */}
        <div className="packaginglayout">
          <h2>[OTHER PROJECTS]</h2>
        </div>
        <div className="gridexpmisc">
          <figure className="cell cell--5">
            <img src="/img/thaissacrender.png" className="img_gallery" />
            <p className="appear">
              NAME: Haus Kitchen <br />
              <br />
              POSITIONING: Playful, simple to make hearty comfort food <br />
              <br />
              {/* TARGET AUDIENCE: GEN Z & MILLENNIALS WITH FULL TIME JOB, DON'T
              WANT TO SPEND LOTS OF TIME GROCERY SHOPPING, WANT TO BE HEALTHY,
              DON'T WANT TO SPEND TOO MUCH TIME COOKING <br />
              <br /> */}
              DELIVERY CHANNEL: Meal kits sold at grocery stores <br />
              <br />
              OVERALL CONCEPT: Delicious and easy to make meals with complex
              flavors and good portions
            </p>
          </figure>
          <figure className="cell cell--video2">
            <video
              muted
              loop
              autoPlay
              webkit-playsInline
              playsInline
              className="img_gallery"
            >
              <source src="./img/boxanim.mp4" type="video/mp4" />
            </video>
            <p className="appear">
              Jewelry box with textured surface and glossy finish
            </p>
          </figure>

          <figure className="cell cell--7">
            <img src="/img/bottle.png" className="img_gallery" />
            <p className="appear">Gin branding</p>
          </figure>
          <figure className="cell cell--8">
            <img src="/img/box1.png" className="img_gallery" />
            <p className="appear">Exploring packaging dielines in 3D space</p>
          </figure>

          {/* <figure className="cell cell--10">
            <img src="/img/trident2.png" className="img_gallery" />
            <p className="appear">
              Ukrainian coat of arms pendant designed in 3D
            </p>
          </figure> */}
        </div>
      </div>
    );
  }
}

export default Project4;
