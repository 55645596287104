import React from "react";
import { HashLink as Link } from "react-router-hash-link";
import Gallerythree from "./03gallery";
import Galleryfour from "./04gallery";
import Galleryfive from "./05gallery";
import Gallerysix from "./06gallery";

class CAD extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
    };
  }

  toggleExpand = () => {
    this.setState((prevState) => ({
      expanded: !prevState.expanded,
    }));
  };
  render() {
    const { expanded } = this.state;

    return (
      <div className="CAD">
        <div id="workdescription"></div>
        <>
          <div className="ButtonMenu">
            <Link to="../Home#about">
              <button className="Menu">&#60;About&#62;</button>
            </Link>
            <Link to="../Home#work">
              <button className="Menu">&#60;Work&#62;</button>
            </Link>
            <button>
              <Link to="../Home#contact" className="Menu">
                &#60;Contact&#62;
              </Link>
            </button>
          </div>
        </>

        <div id="CAD"></div>

        <div className="workbody">
          <figure>
            <img src="/img/enclosure.png" className="img_gallery4" />
          </figure>
          <div id="workdescription">
            {" "}
            <h4>
              CAD - <br />
              <span className="h2description">
                {" "}
                The CAD section showcases my process and skills contributing to
                thoughtful designs and products.{" "}
              </span>
            </h4>
            <section id="projecttext">
              <div className={`projecttext ${expanded ? "expanded" : ""}`}>
                {/* <h2>[INFO]</h2> */}
                <p>
                  [1] Extensive market research and target user analysis inform
                  the design process, ensuring alignment with market demands.{" "}
                  <br />
                  <br />
                  [2] Initial ideas are captured through inspiration boards,
                  sketching, and physical mockups while maintaining the brands'
                  design language.
                  <br />
                  <br />
                  [3] Attention to CMF enhances the visual appeal of designs,
                  creating engaging and desirable aesthetics.
                  <br />
                  <br />
                  [4] 3D modeling in Solidworks provides precise and accurate
                  designs ready for manufacturing.
                  <br />
                  <br />
                  [5] Model iterations using 3D printing technology aid in
                  developing user-friendly and ergonomic designs.
                  <br />
                  <br />
                  [6] Renderings made in Keyshot provide a cohesive 3D
                  visualization of finalized concepts, products, functions, and
                  materials.
                  <br />
                </p>
              </div>
            </section>
            <button className="expand-btn" onClick={this.toggleExpand}>
              {expanded ? "[INFO] -" : "[INFO] + "}
            </button>
          </div>
        </div>
        <div className="galleryplacement">
          <Gallerythree />
        </div>
        <div className="galleryplacement">
          <Galleryfour />
        </div>
        <div className="galleryplacement">
          <Galleryfive />
        </div>
        <div className="galleryplacement">
          <Gallerysix />
        </div>
      </div>
    );
  }
}

export default CAD;
