import { Canvas, Camera, useFrame } from "@react-three/fiber";
import { OrbitControls, Stage, useGLTF } from "@react-three/drei";
import React, { useRef, Suspense, useState, useEffect, Component } from "react";
import { lookAt, wobble } from "../utils/animations";
import { HashLink as Link } from "react-router-hash-link";
import { Helmet } from "react-helmet";
import ScrollIntoView from "react-scroll-into-view";

import TypeWriterEffect from "react-typewriter-effect";

// function Modeltwo({ ...props }) {
//   const group = useRef();
//   const { nodes, materials } = useGLTF("/vrmeshlow.glb");

//   useFrame((state) => {
//     const { position, rotation } = group.current;
//     position.y += wobble(state) / 8000;
//     position.x += wobble(state) / 8000;

//     rotation.y += wobble(state) / 20000;
//     rotation.x += wobble(state) / 20000;
//     rotation.z += wobble(state) / 20000;

//     rotation.y = lookAt(state, rotation.y);
//     rotation.x = lookAt(state, rotation.x);
//   });

//   return (
//     <group ref={group} {...props} dispose={null}>
//       <mesh
//         castShadow
//         receiveShadow
//         geometry={nodes["agoodk1d_2021-11-26_21-23-50001"].geometry}
//         material={materials["Material.002"]}
//         position={[8, 4, -1]}
//         rotation={[-0.53, 0, 4]}
//         scale={0.4}
//       />
//     </group>
//   );
// }

//

function Model({ ...props }) {
  const group = useRef();
  const { nodes, materials } = useGLTF("/webthin.glb");

  useFrame((state) => {
    const { position, rotation } = group.current;
    position.y += wobble(state) / 8000;
    position.x += wobble(state) / 8000;

    rotation.y += wobble(state) / 30000;
    rotation.x += wobble(state) / 30000;
    rotation.z += wobble(state) / 30000;

    rotation.y = lookAt(state, rotation.y);
    rotation.x = lookAt(state, rotation.x);
  });

  return (
    <group ref={group} {...props} dispose={null}>
      <mesh
        receiveShadow
        geometry={nodes["1"].geometry}
        material={materials.Material}
        rotation={[Math.PI / 2, 0, 0]}
        position={(1, 1, 1, -1)}
      />
    </group>
  );
}

class Home extends React.Component {
  render() {
    return (
      <>
        {/* <Helmet>
            <script
              type="text/jsx"
              src="/src/components/channelSplitMaterial.js"
            ></script>
            <script type="text/jsx" src="/src/blotter.js"></script>
          </Helmet> */}
        {/* <div className="wrapone">
          <Suspense>
            <Canvas
              camera={{ position: [10, 10, 40.25], fov: 30 }}
              style={{
                width: "100vw",
                height: "100%",
                left: 0,
                top: 0,
                right: 0,
                position: "absolute",
              }}
            >
              <Modeltwo />
            </Canvas>
          </Suspense>
        </div> */}
        <div className="wrap">
          <Suspense>
            <Canvas
              className="objectresize"
              camera={{ position: [0, -25, 50.25], fov: 18 }}
              style={{
                left: 0,
                top: 0,
                right: 0,
                position: "absolute",
              }}
            >
              {/* <Stage environment="dawn" contactShadowOpacity={1}> */}
              {/* <OrbitControls makeDefault /> */}

              <Model />
              {/* </Stage> */}
            </Canvas>
          </Suspense>
        </div>

        <main>
          <div className="LeftLogo">
            <div id="text">
              <TypeWriterEffect
                className="headertext"
                textStyle={{
                  fontFamily: "Fira Code",
                  width: "20",
                  color: "#eef7ed",
                  fontWeight: 400,
                }}
                startDelay={1000}
                cursorColor="#eef7ed"
                multiText={[
                  "A_GOOD_KID",
                  " ",
                  "A_GOOD_KID",
                  " ",
                  "A_GOOD_KID",
                  " ",
                  "A_GOOD_KID",
                  " ",
                  "A_GOOD_KID",
                  " ",
                  "A_GOOD_KID",
                  " ",
                  "A_GOOD_KID",
                ]}
                loop={true}
                nextTextDelay={900}
                typeSpeed={110}
              />
              {/* <h1 className="headertext">A GOOD KID</h1> */}
            </div>

            <p>by Stefani Pletenetska</p>
          </div>

          <div className="ButtonMenu">
            <ScrollIntoView selector="#about" className="Menu">
              <button>
                {/* <Link to="#about" className="Menu"> */}
                &#60;About&#62;
                {/* </Link> */}
              </button>
            </ScrollIntoView>
            <ScrollIntoView selector="#work" className="Menu">
              <button>
                {/* <a className="Menu" href="#work"> */}
                &#60;Work&#62;
                {/* </a> */}
              </button>
            </ScrollIntoView>
            <ScrollIntoView selector="#contact" className="Menu">
              <button>
                {/* <a className="Menu" href="#contact"> */}
                &#60;Contact&#62;
                {/* </a> */}
              </button>
            </ScrollIntoView>
          </div>
        </main>

        <div className="mainbody">
          <span className="anchor">
            <section id="about">
              <div className="about">
                <h2>[ABOUT] &#9787;</h2>
                <p>
                  Stefani is a versatile designer from Ukraine who constantly
                  learns new skills and accepts challenges.
                  <br /> Mainly focusing on finding ways of combining seemingly
                  unrelated concepts into <br />
                  stunning visuals that captivate one’s mind.
                </p>
              </div>
            </section>
          </span>
          <div className="workborder">
            <div id="work">
              <div className="buttonmenutext">
                <Link to="../Project1#workdescriptionpr">
                  <div class="image-text__title">
                    <h3 class="image-text-home1">
                      <span className="smallnumber">01</span>ARTHROPOD
                    </h3>
                  </div>
                </Link>
                <Link to="../Project2#workdescription">
                  <div class="image-text__titleimage-text-home2">
                    <h3 class="image-text-home2">
                      <span className="smallnumber">02</span>SYNTH
                    </h3>
                  </div>
                </Link>
                <Link to="../Project3#workdescription">
                  <div class="image-text__title">
                    <h3 class="image-text-home3">
                      <span className="smallnumber">03</span>SCOUT
                    </h3>
                  </div>
                </Link>
                <Link to="../Project4#workdescription">
                  <div class="image-text__title">
                    <h3 class="image-text-home4">
                      <span className="smallnumber">04</span>PACKAGING
                    </h3>
                  </div>
                </Link>
                <Link to="../CAD#workdescription">
                  <div class="image-text__title">
                    <h3 class="image-text-home5">
                      <span className="smallnumber">05</span>CAD
                    </h3>
                  </div>
                </Link>
                <Link to="../Misc#centered">
                  <div class="image-text__title">
                    <h3 class="image-text-home6">
                      <span className="smallnumber">06</span>MISC
                    </h3>
                  </div>
                </Link>
              </div>
            </div>
          </div>

          <footer id="connect">
            <span id="contact">
              <div className="footer">
                <section>
                  <h2>
                    [Contact]<section className="dot-flashing"></section>
                  </h2>
                  <p>
                    Email: <br />
                    <br />
                    agoodkid.main@gmail.com
                  </p>
                </section>

                <div className="Links">
                  <a
                    className="socials"
                    href="https://www.instagram.com/a.good.kid/?igshid=zy7y9cvb1hwh"
                    target="_blank"
                  >
                    INSTAGRAM
                  </a>
                  <p>-</p>

                  <a
                    className="socials"
                    href="https://www.behance.net/stefanipletenetska"
                    target="_blank"
                  >
                    BEHANCE
                  </a>
                  <p>-</p>

                  <a
                    className="socials"
                    href="https://www.youtube.com/channel/UCgqIjHXkqNJFnOESi7GL0Bg"
                    target="_blank"
                  >
                    YOUTUBE
                  </a>
                </div>
              </div>
            </span>
          </footer>
        </div>
      </>
    );
  }
}

export default Home;
