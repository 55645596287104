import React from "react";
import { HashLink as Link } from "react-router-hash-link";
import Home from "./Home";

//

class Misc extends React.Component {
  render() {
    return (
      <div className="Misc">
        <>
          <div className="ButtonMenu">
            <Link to="../Home#about">
              <button className="Menu">&#60;About&#62;</button>
            </Link>
            <Link to="../Home#work">
              <button className="Menu">&#60;Work&#62;</button>
            </Link>
            <button>
              <Link to="../Home#contact" className="Menu">
                &#60;Contact&#62;
              </Link>
            </button>
          </div>

          <div id="centered">
            {" "}
            <h1 classname="workdescription">05_Misc</h1>{" "}
          </div>
        </>
        <div className="workbody">
          <div className="gridexp">
            <figure className="cell cell--video5">
              <video
                muted
                loop
                autoPlay
                webkit-playsInline
                playsInline
                className="img_gallery"
              >
                <source src="/img/elephant.mov" type="video/mp4" />
              </video>
              <p className="appear">Animation using After Effects</p>
            </figure>

            <figure className="cell cell--video6">
              <video
                muted
                loop
                autoPlay
                webkit-playsInline
                playsInline
                className="img_gallery"
              >
                <source src="/img/clothanim.mp4" type="video/mp4" />
              </video>
              <p className="appear">Layered 3D garment design</p>
            </figure>

            <figure className="cell cell--31">
              <img src="/img/MASK6.png" className="img_gallery" />
              <p className="appear">Face Jewelry: sculpted in Blender</p>
            </figure>
            <figure className="cell cell--32">
              <img src="/img/device.png" className="img_gallery" />
              <p className="appear">Solar Walkman sci-fi device</p>
            </figure>
            <figure className="cell cell--33">
              <img src="/img/jewelry5.png" className="img_gallery" />
              <p className="appear">Armor 3D concept</p>
            </figure>
            <figure className="cell cell--34">
              <img src="/img/vrsculpt.png" className="img_gallery" />
              <p className="appear">Abstract VR sculpture</p>
            </figure>
            <figure className="cell cell--35">
              <img src="/img/vest.jpg" className="img_gallery" />
              <p className="appear">Puffer Vest: 3D garment design</p>
            </figure>
            <figure className="cell cell--36">
              <img src="/img/misc8.png" className="img_gallery" />
              <p className="appear">Experiments in Cinema4D</p>
            </figure>
            <figure className="cell cell--37">
              <img src="/img/squid6.jpg" className="img_gallery" />
              <p className="appear">
                Outlines: made in Houdini rendered in Blender
              </p>
            </figure>
            <figure className="cell cell--38">
              <img src="/img/collab.jpg" className="img_gallery" />
              <p className="appear">Collaboration artwork with Ap0cene</p>
            </figure>
            <figure className="cell cell--39">
              <img src="/img/STATUE3.jpg" className="img_gallery" />
              <p className="appear">Poster design from my 3D scan</p>
            </figure>
            <figure className="cell cell--40">
              <img src="/img/trident.jpg" className="img_gallery" />
              <p className="appear">
                ВОЛЯ / FREEDOM Silver pendant <br />
                <br /> Designed and polished by me
              </p>
            </figure>
            <figure className="cell cell--41">
              <img src="/img/bonelogo.png" className="img_gallery" />
              <p className="appear">3D Logo for a twitch streamer</p>
            </figure>
            <figure className="cell cell--42">
              <img src="/img/vase.png" className="img_gallery" />
              <p className="appear">Exploring geo nodes in Blender</p>
            </figure>
          </div>
        </div>
      </div>
    );
  }
}

export default Misc;
